import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import image1 from "../assets/hero/1.jpg";
import image2 from "../assets/hero/2.jpg";
import image3 from "../assets/hero/3.jpg";
import image4 from "../assets/hero/4.jpg";
import image5 from "../assets/hero/5.jpg";

// Import your images here
const images = [
  image1,image2,image3,image4,image5
];

const Services = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div
      id="expert"
      className="services-page bg-gray-100 min-h-[110vh] flex flex-col items-center justify-center"
    >
      <h2 className="text-4xl lg:text-5xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-500 to-green-900 mb-8 shadow-lg drop-shadow-md">
        Our Main Services
      </h2>

      <div className="w-full md:w-3/4 lg:w-[1120px] pb-12"> {/* Add padding at bottom */}
        <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index}>
              <img
                src={image}
                alt={`Service ${index + 1}`}
                className="w-full h-auto object-cover rounded-lg"
                style={{ width: "100%", height: "auto", maxHeight: "560px" }}
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Services;
